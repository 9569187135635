@import url('https://fonts.cdnfonts.com/css/ica-rubrik-black');
@import url('https://fonts.cdnfonts.com/css/poppins');

.banner {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.banner .slider {
    position: absolute;
    width: 150px;
    height: 250px;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%) perspective(1000px);
    transform-style: preserve-3d;
    animation: autoRun 20s linear infinite;
}

@keyframes autoRun {
    from {
        transform: translate(-50%, -50%) perspective(1000px) rotateX(-16deg) rotateY(0deg);
    }
    to {
        transform: translate(-50%, -50%) perspective(1000px) rotateX(-16deg) rotateY(360deg);
    }
}

.banner .slider .item {
    position: absolute;
    inset: 0;
    transform: 
        rotateY(calc( (var(--position) - 1) * (360 / var(--quantity)) * 1deg))
        translateZ(300px);
}

.banner .slider .item img {
    width: 80%;
    height: 80%;
    object-fit: cover;
}

@media screen and (max-width: 1023px) {
    .banner .slider {
        width: 160px;
        height: 200px;
    }
}

@media screen and (max-width: 767px) {
    .banner .slider {
        width: 120px;
        height: 150px;
    }
}