@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos básicos adicionais */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

a {
  color: #4BB5FF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: #4BB5FF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

button:hover {
  background-color: #3a9ee6;
}